import React, { useState } from "react";
import { ChromePicker } from "react-color";
import { useNavigate } from "react-router-dom";

const StepFour = () => {
  const navigate = useNavigate();
  const [color, setColor] = useState(localStorage.getItem("color") || "#fff");

  const handleChangeComplete = (color) => {
    setColor(color.hex);

    localStorage.setItem("color", color.hex);
  };

  return (
    <div className="2xl:flex lg:flex 2xl:pt-0 pt-14 ">
      <div className="2xl:w-[50%] w-full  flex  2xl:justify-start lg:justify-start justify-center 2xl:pl-40 lg:pl-40 items-start 2xl:mt-12">
        <div className=" relative z-10 flex justify-start">
          <img
            src="/assets/images/iphone5.png"
            className="bg-contain w-full h-[580px]  z-10"
            alt=""
          />

          <div className="absolute w-full -z-0 2xl:h-[570px] lg:h-[570px] h-[99%] p-1 overflow-hidden 2xl:top-1 top-1 rounded-[50px]">
            <div
              style={{
                background: color,
              }}
              className="w-full h-full p-6 flex justify-end"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-7 h-7 text-"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>

              <div className="absolute bottom-20 right-[43%]">
                <img src="/assets/images/circle.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="2xl:w-[50%] w-full 2xl:pt-20 lg:pt-10 pt-10 flex justify-center ">
        <div>
          <p className="text-[20px] text-[#070E15] font-normal">
            Choose your Song Color
          </p>
          <div className="h-[2px] w-[60px] bg-[#01010B] my-1"></div>

          <div className="w-full mt-8">
            <ChromePicker
              color={color}
              onChange={handleChangeComplete}
              styles={{
                default: {
                  color: { display: "none" },
                  saturation: {
                    borderRadius: "6px",
                  },
                  toggles: {
                    gap: "4px",
                    display: "grid",
                  },
                  controls: {
                    marginBottom: "-5px",
                  },
                },
              }}
            />
          </div>

          <div className="flex justify-center w-full 2xl:pt-12 lg:pt-12 pt-10  2xl:px-[15%] lg:px-[20%] md:px-[20%]">
            <button
              onClick={() => navigate("/review")}
              style={{
                background:
                  "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
              }}
              className="text-white font-semibold rounded-[50px] px-10 2xl:py-4 py-3 w-full text-md"
            >
              Next
            </button>
          </div>
          <p className="2xl:text-[14px]  text-[14px] 2xl:pt-6 pt-2 2xl:pb-20 pb-5 text-center text-[#858688] font-normal ">
            Questions? Check out our{" "}
            <span
              onClick={() =>
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/lightcert-subscription.appspot.com/o/Lightcert%20Customization%20Guide%20(1).pdf?alt=media&token=4c7b94a3-938a-435c-9836-9e0b3f96268e"
                )
              }
              className="text-[#FC53A4] underline cursor-pointer"
            >
              Customization Guide
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepFour;
