import React, { useEffect, useState } from "react";
import { SOCIAL_ICONS } from "../../constants/data";
const FinalView = ({ setView, view }) => {
  const [songDetail, setSongDetail] = useState();
  const [bgColor, setBgColor] = useState("#000000");

  useEffect(() => {
    const color = localStorage.getItem("color");
    setBgColor(color);
  }, []);

  useEffect(() => {
    const storedValuesString = localStorage.getItem("details");
    const storedValues = JSON.parse(storedValuesString);
    setSongDetail(storedValues);
  }, []);

  return (
    <div className=" relative z-10 flex justify-start">
      <img
        src="https://ik.imagekit.io/u7qex2chd/iphone5.svg?updatedAt=1705495576746"
        className="bg-contain w-full h-[580px]  z-10"
        alt=""
      />

      <div className="absolute bottom-10 left-6 z-50">
        <div className="flex items-center gap-6 mt-4">
          {SOCIAL_ICONS.map((item) => (
            <img
              onClick={() => window.open(item.url)}
              src={item?.icon}
              className="w-7 h-7 cursor-pointer"
              alt=""
            />
          ))}
        </div>
        <img
          src="/assets/images/final-back.svg"
          className="w-8 h-8 mt-4 -ml-[2px] cursor-pointer"
          alt=""
          onClick={() => setView(6)}
        />
      </div>

      <div className="absolute w-full z-0 h-[570px]  p-1 overflow-hidden 2xl:top-1 top-1 rounded-[50px]">
        <div
          style={{
            background: bgColor,
          }}
          className="w-full h-full px-2  justify-start"
        >
          <div className="h-[48%] w-full flex justify-center items-center">
            <img src="/assets/images/play.svg" className="w-20 h-20" alt="" />
          </div>
          <div
            style={{
              boxShadow: "6px 4px 20px 8px rgba(0, 0, 0, 0.25)",
            }}
            className="h-[52%] bg-black rounded-t-[20px] p-4"
          >
            <div>
              <p className="text-[19px] text-[#fff] capitalize font-semibold ">
                {songDetail?.name}
              </p>
              <p className="text-[12px] text-[#FC53A4] capitalize font-semibold">
                {songDetail?.title}
              </p>
            </div>
            <img
              src="/assets/images/download.svg"
              className="w-8 h-8 mt-3"
              alt=""
            />
            <div className="h-[0.8px] w-full bg-[#7350FF5C] my-4"></div>
            <p className="text-[#fff] font-semibold  w-full">Share</p>
            <div className="h-[4px] w-6 bg-[#FC53A4] rounded-xl"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinalView;
