// src/components/FanProjectGuide.js
import React from "react";

const BlogSection = ({ fanProjectGuide }) => {
  const renderParagraph = (paragraph) => {
    const boldParts = paragraph.match(/\*\*([^*]+)\*\*/g);
    if (boldParts) {
      return (
        <p className="pb-6" key={paragraph}>
          {boldParts.map((part, idx) => (
            <React.Fragment key={idx}>
              <span className="font-semibold text-[18px] text-[#363940]">
                {part.replace(/\*\*/g, "")}
              </span>
              <br />
            </React.Fragment>
          ))}
          {paragraph.split(/\*\*([^*]+)\*\*/).pop()} {/* Add this line */}
        </p>
      );
    } else {
      return <p className="pb-6">{paragraph}</p>;
    }
  };
  return (
    <div>
      <section className="mb-8 mt-3 text-[#363940] text-[14px]">
        <h2 className="text-[18px] font-semibold text-[#FC53A4] mb-4">
          {fanProjectGuide?.title}
        </h2>
        {fanProjectGuide?.content?.map(renderParagraph)}
      </section>
    </div>
  );
};

export default BlogSection;
