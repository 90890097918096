import React from "react";
import Slider from "react-slick";
import { ARTIST, FAN } from "../../constants/data";
const Artist = ({ itemIndex }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerMods: false,
  };
  return (
    <div className="w-[220px] h-auto ">
      <img src="/assets/images/logo-small.svg" alt="" />

      {itemIndex === 0 ? (
        <>
          <Slider {...settings}>
            {ARTIST?.map((item) => (
              <div>
                <p className="text-white leading-5 pt-8 font-semibold text-[18px]">
                  {item}
                </p>
              </div>
            ))}
          </Slider>
        </>
      ) : (
        <>
          {" "}
          <Slider {...settings}>
            {FAN?.map((item) => (
              <div>
                <p className="text-white leading-5 pt-8 font-semibold text-[18px]">
                  {item}
                </p>
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  );
};

export default Artist;
