import React, { useState, useEffect } from "react";

const ViewColor = ({ setView, view }) => {
  const [isPause, setIsPause] = useState(false);
  const [bgColor, setBgColor] = useState("#000000");

  const handlePause = () => {
    setIsPause(!isPause);
  };

  useEffect(() => {
    if (isPause) {
      setTimeout(() => {
        setView(view + 1);
      }, 1000);
    }
  }, [isPause]);

  useEffect(() => {
    const color = localStorage.getItem("color");
    setBgColor(color);
  }, []);

  return (
    <div className=" relative z-10 flex justify-start">
      <img
        src="https://ik.imagekit.io/u7qex2chd/iphone5.svg?updatedAt=1705495576746"
        className="bg-contain w-full h-[580px]  z-10"
        alt=""
      />

      <div className="w-12 h-12  absolute z-20 bottom-16 flex justify-center items-center right-[41.5%]">
        {" "}
        <img
          onClick={handlePause}
          src={
            isPause ? "/assets/images/circle2.png" : "/assets/images/circle.png"
          }
          className="cursor-pointer z-50 "
          alt=""
        />
      </div>
      <div className="absolute top-8 right-7 h-8 w-8 z-20">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-7 h-7 cursor-pointer"
          onClick={() => setView(5)}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18 18 6M6 6l12 12"
          />
        </svg>
      </div>

      <div className="absolute w-full z-0 h-[570px]  p-1 overflow-hidden 2xl:top-1 top-1 rounded-[50px]">
        <div
          style={{
            background: bgColor,
          }}
          className="w-full h-full p-6 flex justify-start"
        ></div>
      </div>
    </div>
  );
};

export default ViewColor;
