import React, { useState, useEffect } from "react";

const ViewArtist1 = ({ setIsSelect, isSelect, setView, view }) => {
  const [songDetail, setSongDetail] = useState();
  const bannerImage = window.localStorage.getItem("banner-image");
  const songImage = window.localStorage.getItem("song-image");

  useEffect(() => {
    const storedValuesString = localStorage.getItem("details");
    const storedValues = JSON.parse(storedValuesString);
    setSongDetail(storedValues);
  }, []);

  return (
    <>
      <div className="w-[250px]   relative">
        <div
          style={{
            backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.05279335855435929) 66%, rgba(0,0,0,0.05279335855435929) 67%, rgba(0,0,0,0.5429894369857318) 100%),url(${bannerImage})`,
          }}
          className="w-full absolute bg-center bg-cover bg-no-repeat -top-24 h-[200px]  rounded-2xl flex items-end p-2"
        >
          <div>
            <p className="text-[19px] text-[#fff] capitalize font-semibold ">
              {songDetail?.name}
            </p>
            <p className="text-[12px] text-[#FC53A4] capitalize font-semibold">
              {songDetail?.venue}
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-24 ">
        <p className="text-[#fff] font-semibold pt-4 pl-2 w-full">
          Select Song
        </p>
        <div className="h-[4px] w-6 bg-[#FC53A4]  ml-2 rounded-xl"></div>

        <div
          onClick={() => setIsSelect(!isSelect)}
          className={`h-[55px] w-full mt-5  cursor-pointer flex items-center justify-between px-2 border-t border-b border-[#fc53a52c] ${
            isSelect && "bg-[#E81F762E]"
          }`}
        >
          <div className="flex items-center">
            <div className="w-[40px] h-[40px] rounded-lg overflow-hidden">
              <img
                src={songImage}
                alt=""
                className="object-cover w-full h-full"
              />
            </div>
            <p className="text-[13px] text-white capitalize pl-3">
              {songDetail?.title}
            </p>
          </div>
          <img
            src="/assets/images/right-arrow.svg"
            className="w-4 h-4"
            alt=""
          />
        </div>

        {isSelect && (
          <div className="w-full h-auto pt-20   flex justify-center">
            <button
              onClick={() => setView(view + 1)}
              style={{
                background:
                  "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
              }}
              className="text-white font-semibold w-[80%] cursor-pointer   rounded-[50px]  py-2 text-md  "
            >
              Lights Up!
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewArtist1;
