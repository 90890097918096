import React, { useState, useEffect } from "react";
import Container from "../../components/container";
import Steps from "../../components/steps";
import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepThree from "./step-three";
import StepFour from "./step-four";

const Main = () => {
  const [step, setStep] = useState(() => {
    const savedStep = parseInt(window.localStorage.getItem("step"), 10);
    return isNaN(savedStep) ? 1 : savedStep;
  });
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    window.localStorage.setItem("step", step);
  }, [step]);

  useEffect(() => {
    const savedStep = parseInt(window.localStorage.getItem("step"), 10);
    if (!isNaN(savedStep)) {
      setCurrent(savedStep);
    }
  }, [step]);

  return (
    <Container>
      <Steps
        step={step}
        setStep={setStep}
        setCurrent={setCurrent}
        current={current}
      />
      {current === 1 && <StepOne setStep={setStep} />}
      {current === 2 && <StepTwo setStep={setStep} />}
      {current === 3 && <StepThree setStep={setStep} />}
      {current === 4 && <StepFour setStep={setStep} />}
    </Container>
  );
};

export default Main;
