import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./pages/home";
import { useEffect } from "react";
import HowItWorks from "./pages/how-it-works";
import Warning from "./pages/warning";
import Customize from "./pages/customize";
import Review from "./pages/review";
import Payment from "./pages/payment";
import PaymentSuccess from "./pages/payment-succuss";
import { projectDetails } from "./utils/storeAccessCode";
export function App() {
  const navigate = useNavigate();

  const handleBeforeUnload = (event) => {
    const message =
      "Are you sure you want to leave? Your changes may not be saved.";
    alert(message);
    event.returnValue = message;
  };

  const redirect = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);

    setTimeout(() => {
      localStorage.clear();
      navigate("/");
    }, 500);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    redirect();
  }, []);

  console.log(
    "process.env.REACT_APP_FIREBASE_API",
    process.env.REACT_APP_PROJECT_ID
  );

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/how-it-works" element={<HowItWorks />} />
      <Route path="/warning" element={<Warning />} />
      <Route path="/customize" element={<Customize />} />
      <Route path="/review" element={<Review />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/payment-success" element={<PaymentSuccess />} />
    </Routes>
  );
}

export default App;
