import React, { useState } from "react";

const Allow = ({ view, setView }) => {
  const [itemIndex, setItemIndex] = useState();
  const arr = ["dont allow", "allow"];
  return (
    <div className="w-full  rounded-[17px] overflow-hidden bg-white">
      <div className="w-full p-2 pt-3">
        <p className="text-[16px] font-bold text-[#01010B] text-center leading-5">
          “Lightcert” Would Like To Send You Notifications
        </p>
        <p className="text-[11px] font-normal text-[#01010B] text-center leading-4 pt-1">
          Notifications may include alerts, sounds, and icon badges. These can
          be configured in Settings.
        </p>
      </div>
      <div className="w-full border-t mt-3  border-[#0000001A] grid grid-cols-2 text-center text-[#0071F8] text-[15px] font-normal ">
        {arr.map((item, index) => (
          <div
            onClick={() => {
              setItemIndex(index);
              localStorage.setItem(
                "isNotification",
                item === "allow" ? true : false
              );
              setTimeout(() => {
                setView(view + 1);
              }, 700);
            }}
            className={`border-r capitalize border-[#0000001A] p-2 font-semibold hover:font-semibold cursor-pointer ${
              index === itemIndex && "bg-blue-500 text-white"
            }`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Allow;
