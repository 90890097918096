import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
const StepTwo = ({ setStep }) => {
  const [details, setDetails] = useState();
  const [value, onChange] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const inputRef = useRef();

  const handleCalendarChange = (newValue) => {
    formik.setFieldValue("date", newValue.toISOString().split("T")[0]);
    onChange(newValue);
    setShowCalendar(false);
  };

  const handleInputClick = () => {
    setShowCalendar(!showCalendar);
  };

  const handleClickOutside = (event) => {
    if (inputRef.current && !inputRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  // Attach a global click event listener to close the calendar when clicking outside the input and calendar
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("name is Required")
      .max(25, "Name must be at most 25 characters"),
    venue: yup
      .string()
      .required("venue is Required")
      .max(25, "Venue must be at most 25 characters"),
    date: yup.string().required("date is Required"),
    title: yup
      .string()
      .required("Title is required")
      .max(25, "Title must be at most 25 characters")
      .test(
        "capitalized-characters",
        "Title must have at most 18 characters when capitalized",
        (value) => {
          const capitalizedTitle = value.replace(/[^A-Z]/g, "");
          return capitalizedTitle.length <= 18;
        }
      ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: details ? details?.name : "",
      venue: details ? details?.venue : "",
      date: details ? details?.date : "",
      title: details ? details?.title : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const valuesString = JSON.stringify(values);
      localStorage.setItem("details", valuesString);
      localStorage.setItem("step", 3);
      setStep(3);
    },
  });

  useEffect(() => {
    const storedValuesString = localStorage.getItem("details");
    const storedValues = JSON.parse(storedValuesString);
    setDetails(storedValues);
  }, []);

  return (
    <div className="flex justify-center w-full h-auto 2xl:pt-20 lg:pt-6 md:pt-6 pt-12">
      <div className="text-center">
        <p className="2xl:text-[40px] lg:text-[28px] text-[28px] text-[#070E15] font-normal">
          Let’s talk details
        </p>
        <p className="2xl:text-[18px] lg:text-[14px] md:text-[14] text-[18px] text-[#858688] font-normal  2xl:pt-2">
          Tell us a bit more about your project.
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <div className=" justify-center pt-7 2xl:pb-7 pb-4">
              <input
                type="text"
                id="name"
                class="bg-[#EDF2F7] border  text-[#7A828A] placeholder:text-[#7A828A] text-[15px]  placeholder:text-[15px] font-semibold  rounded-[14px] block 2xl:w-[498px] lg:w-[498px] md:w-[450px] w-full  2xl:h-[60px] h-[50px] px-4"
                placeholder="Artist name"
                required
                onChange={formik.handleChange}
                value={formik.values.name}
                name="name"
              />
              {formik.errors.name && formik.touched.name && (
                <p className="text-red-500 text-[12px] pt-1 text-left font-normal">
                  {formik.errors.name}
                </p>
              )}
              <p className="2xl:text-[15px] lg:text-[12px] md:text-[12px] text-[14px] pt-1 text-left text-[#858688] font-normal ">
                The artist the project is for.
              </p>
            </div>
            <div className=" justify-center 2xl:pb-7 pb-4">
              <input
                type="text"
                id="venue"
                class="bg-[#EDF2F7] border  text-[#7A828A] placeholder:text-[#7A828A] font-semibold text-[15px]  placeholder:text-[15px]  rounded-[14px] block 2xl:w-[498px] lg:w-[498px] md:w-[450px] w-full  2xl:h-[60px] h-[50px] px-4"
                placeholder="Venue"
                required
                onChange={formik.handleChange}
                value={formik.values.venue}
                name="venue"
              />
              {formik.errors.venue && formik.touched.venue && (
                <p className="text-red-500 text-[12px] pt-1 text-left font-normal">
                  {formik.errors.venue}
                </p>
              )}
              <p className="2xl:text-[15px] lg:text-[12px] md:text-[12px] text-[14px] pt-1 text-left text-[#858688] font-normal ">
                Where the project is happening.
              </p>
            </div>
            <div className=" justify-center relative 2xl:pb-7 pb-4">
              {showCalendar && (
                <div className="absolute -top-20 2xl:left-0 lg:left-0 md:left-0  -left-6">
                  <Calendar
                    onChange={handleCalendarChange}
                    minDate={new Date()}
                    value={value}
                    className="rounded-2xl"
                  />
                </div>
              )}
              <input
                type="text"
                onClick={handleInputClick}
                className="bg-[#EDF2F7] border cursor-pointer text-[#7A828A] font-semibold placeholder:text-[#7A828A] text-[15px]  placeholder:text-[15px]  rounded-[14px] text-left placeholder:text-left 2xl:w-[498px] lg:w-[498px] md:w-[450px] w-full  2xl:h-[60px] h-[50px] px-4"
                value={
                  value instanceof Date
                    ? new Date(
                        value.getTime() - value.getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .split("T")[0]
                    : ""
                }
              />

              <p className="2xl:text-[15px] lg:text-[12px] md:text-[12px] text-[14px] pt-1 text-left text-[#858688] font-normal ">
                When the project is happening.
              </p>
            </div>
            <div className=" justify-center pb-7 ">
              <input
                type="text"
                id="title"
                className="bg-[#EDF2F7] border  text-[#7A828A] font-semibold placeholder:text-[#7A828A] text-[15px]  placeholder:text-[15px]  rounded-[14px] block 2xl:w-[498px] lg:w-[498px] md:w-[450px] w-full  2xl:h-[60px] h-[50px] px-4"
                placeholder="Song title"
                required
                onChange={formik.handleChange}
                value={formik.values.title}
                name="title"
              />
              {formik.errors.title && formik.touched.title && (
                <p className="text-red-500 text-[12px] pt-1 text-left font-normal">
                  {formik.errors.title}
                </p>
              )}
              <p className="2xl:text-[15px] lg:text-[12px] md:text-[12px] text-[14px] pt-1 text-left text-[#858688] font-normal ">
                The song you would like to do the fan project for.{" "}
              </p>
            </div>
          </div>

          <div className="flex justify-center 2xl:pt-10 lg:pt-8 md:pt-8 pt-4  2xl:px-[15%] lg:px-[20%] md:px-[20%] px-[13%]">
            <button
              style={{
                background:
                  "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
              }}
              className="text-white font-semibold rounded-[50px] px-10 2xl:py-5  py-3 w-full text-md"
            >
              Next
            </button>
          </div>
        </form>
        <p className="2xl:text-[14px]  text-[14px] 2xl:pt-6 pt-2 2xl:pb-20 pb-5 text-center text-[#858688] font-normal ">
          Questions? Check out our{" "}
          <span
            onClick={() =>
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/lightcert-subscription.appspot.com/o/Lightcert%20Customization%20Guide%20(1).pdf?alt=media&token=4c7b94a3-938a-435c-9836-9e0b3f96268e"
              )
            }
            className="text-[#FC53A4] underline cursor-pointer"
          >
            Customization Guide
          </span>
        </p>
      </div>
    </div>
  );
};

export default StepTwo;
