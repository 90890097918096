import React, { useState } from "react";
import ReviewSection from "./review-section";
import { useNavigate } from "react-router-dom";
import Container from "../../components/container";
import Allow from "./allow";
import Artist from "./artist";
import ViewArtist1 from "./view-artist1";

const Main = () => {
  const navigate = useNavigate();
  const [view, setView] = useState(1);
  const [itemIndex, setItemIndex] = useState(0);
  const [isSelect, setIsSelect] = useState(false);
  const [codeValue, setCodeValue] = useState("");
  const access_code = window.localStorage.getItem("access_code");
  const [isError, setIsError] = useState(codeValue ? true : false);
  const [errText, setErrText] = useState("Access code is required");

  return (
    <>
      <Container>
        <div className="w-full 2xl:pt-10 pt-6">
          <img
            onClick={() => navigate("/customize")}
            src="/assets/images/back.svg"
            className="cursor-pointer 2xl:w-20 w-14"
            alt=""
          />
        </div>
        <div className="2xl:pt-16 lg:pt-16 md:pt-16 pt-8 ">
          <p className="2xl:text-[48px] lg:text-[28px]  text-[24px] font-bold text-[#000] text-center">
            Review Before Publishing
          </p>

          <p className="text-[#070E15] 2xl:text-[24px] lg:text-[24px] md:text-[24px] text-[20px]  font-normal 2xl:py-5 lg:py-5 md:py-5 py-2 text-center">
            Your Project Code: {access_code}
          </p>
        </div>
        <ReviewSection
          isNextButton={view === 1 || view === 2 || view === 4 ? false : true}
          view={view}
          setView={setView}
          setItemIndex={setItemIndex}
          itemIndex={itemIndex}
          codeValue={codeValue}
          access_code={access_code}
          setIsError={setIsError}
          setErrText={setErrText}
        >
          {view === 1 ? (
            <img src="/assets/images/main-logo.svg" alt="" />
          ) : view === 2 ? (
            <Allow view={view} setView={setView} />
          ) : view === 3 ? (
            <Artist itemIndex={itemIndex} />
          ) : view === 4 ? (
            <div className="w-full flex  justify-center">
              <div>
                <input
                  type="text"
                  className="w-[200px] bg-transparent rounded-xl mt-10  px-2 py-3 border text-white border-[#FC53A4] placeholder:text-white"
                  placeholder="Access Code"
                  onChange={(e) => setCodeValue(e.target.value)}
                />
                {isError && (
                  <>
                    {access_code !== codeValue && (
                      <p className="text-white text-[12px] pt-2 pl-1">
                        {errText}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : view === 5 ? (
            <ViewArtist1
              isSelect={isSelect}
              setIsSelect={setIsSelect}
              setView={setView}
              view={view}
            />
          ) : null}
        </ReviewSection>
      </Container>
    </>
  );
};

export default Main;
