import React from "react";
import Container from "../../components/container";
import { useNavigate } from "react-router-dom";
const WarningSection = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full 2xl:pt-10 pt-4">
      <Container>
        <img
          src="/assets/images/logoo.svg"
          onClick={() => navigate("/")}
          className="cursor-pointer"
          alt=""
        />

        <div className="flex justify-center  2xl:pt-10 lg:pt-7 pt-8">
          <p className="2xl:text-[85px] lg:text-[70px] text-[55px] font-semibold text-[#000]">
            Warning!
          </p>
        </div>
        <div className="flex justify-center 2xl:pt-6 pt-6">
          <img
            src="/assets/images/warning.png"
            alt=""
            className="2xl:w-[15%] lg:w-[10%] md:w-[50%] w-[70%]"
          />
        </div>
        <div className="text-center">
          <p className="2xl:text-[24px]  lg:text-[18px] md:text-[18px] text-[21px] font-normal pt-8 text-[#858688] 2xl:px-[33%] lg:px-[35%] ">
            Once you begin designing, do not exit out of this page until you’ve
            checked out, or you will lose your progress!
          </p>
        </div>

        <div className="flex justify-center mb-8 2xl:pt-12 lg:pt-10 md:pt-10 pt-[67px] xl:px-[38%] lg:px-[30%] md:px-[20%]">
          <button
            onClick={() => navigate("/customize")}
            style={{
              background: "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
            }}
            className="text-white font-semibold rounded-[50px] px-10 2xl:py-5  py-3 w-full text-lg"
          >
            Got it
          </button>
        </div>
      </Container>
    </div>
  );
};

export default WarningSection;
