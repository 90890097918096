import React, { useRef, useEffect } from "react";
import { storeStep1 } from "../../utils/storeAccessCode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
export default function Paypal() {
  const paypal = useRef();
  const navigate = useNavigate();
  const access_code = window.localStorage.getItem("access_code");
  const storedValuesString = window.localStorage.getItem("details");
  const storedValues = JSON.parse(storedValuesString);
  const banner_image = window.localStorage.getItem("banner-image");
  const song_image = window.localStorage.getItem("song-image");
  const theme_color = window.localStorage.getItem("color");

  const project_data = {
    details: {
      ...storedValues,
      theme_color: theme_color,
    },
    project_images: {
      banner_image: banner_image,
      song_image: song_image,
    },
  };

  useEffect(() => {
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: "Cool project",
                amount: {
                  currency_code: "USD", // Change currency to USD
                  value: 50,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          const payload = {
            ...project_data,
            payment: {
              payment_id: order?.id,
              email_address: order?.payer?.email_address,
              name: order?.payer?.name,
              payer_id: order?.payer?.payer_id,
              purchase_units: order?.purchase_units?.[0]?.amount,
            },
            documentId: `${order?.payer?.email_address}`,
            accessCode: access_code,
          };

          console.log("payload", payload);
          if (order) {
            await storeStep1(payload);
            toast.success("Payment successful");
            localStorage.clear();
            setTimeout(() => {
              navigate("/payment-success");
            }, 1500);
          }
        },
        onError: (err) => {
          // Handle errors
          console.error("PayPal error:", err);
          toast.error("Something went wrong");
        },
        style: {
          layout: "horizontal", // horizontal | vertical
          color: "blue", // gold | blue | silver | white | black
          shape: "pill", // pill | rect
          label: "pay", // checkout | pay | buynow | installment
          height: 50,
        },
      })
      .render(paypal.current);
  }, []);

  return (
    <div className="mt-10">
      <ToastContainer />
      <div ref={paypal} className=" 2xl:w-48 lg:w-[48] md:w-48 w-56 h-[50px]" />
    </div>
  );
}
