import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { projectDetails } from "../../utils/storeAccessCode";
const StepOne = ({ setStep }) => {
  const [accessCode, setAccessCode] = useState(
    localStorage.getItem("access_code") || ""
  );

  const [isErr, setIsErr] = useState(false);

  const validationSchema = yup.object().shape({
    code: yup.string().required("Code is Required"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: accessCode ? accessCode : "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const code = values.code;
      const hasMatchingDate = projectDetails.some(
        (project) => project?.accessCode === code
      );

      if (hasMatchingDate) {
        setIsErr(true);
      } else {
        localStorage.setItem("access_code", code);
        localStorage.setItem("step", 2);
        setStep(2);
        setIsErr(false);
      }
    },
  });

  return (
    <div className="flex justify-center h-auto 2xl:pt-32 lg:pt-16 md:pt-7 pt-24">
      <div className="text-center">
        <p className="2xl:text-[40px] lg:text-[28px] text-[28px] text-[#070E15] font-normal">
          Choose your project code
        </p>
        <p className="2xl:text-[18px] lg:text-[14px] md:text-[14] text-[18px] text-[#858688] font-normal 2xl:px-[20%] lg:px-[20%] md:px-[20%] 2xl:pt-4 pt-2">
          Fans will use this code to access your project.
          <p className="2xl:pt-3 pt-1">
            Tip: Choose something identifiable, but not too wordy. Examples:
            nyc_ lot_day1, louis_LA_day2
          </p>
          <p className="2xl:pt-3 pt-1 font-semibold">
            Write this down somewhere safe - you'll need it.
          </p>
        </p>
        <form onSubmit={formik.handleSubmit}>
          <div className="flex justify-center pt-7 pb-7">
            <input
              type="text"
              id="code"
              class="bg-[#EDF2F7] border  text-[#7A828A] placeholder:text-[#7A828A] text-[15px] placeholder:text-[15px] rounded-[14px] block 2xl:w-[55%] lg:w-[60%] md:w-full w-[80%] 2xl:h-[60px] h-[50px] px-4"
              placeholder="Code name"
              onChange={formik.handleChange}
              value={formik.values.code}
              name="code"
              required
            />
          </div>

          <div className="flex justify-center 2xl:pt-4 pt-2  2xl:px-[25%] px-[20%]">
            <button
              type="submit"
              style={{
                background:
                  "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
              }}
              className="text-white font-semibold rounded-[50px] px-10 2xl:py-5  py-3 w-full text-md"
            >
              Next
            </button>
          </div>

          {isErr && (
            <p className="2xl:text-[18px] lg:text-[14px] md:text-[14] text-[18px] text-[#FC53A4] font-normal 2xl:px-[20%] lg:px-[20%] md:px-[20%] 2xl:pt-4 pt-2 pb-4">
              This code is already in use
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default StepOne;
