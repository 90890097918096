// src/components/ReachOutToArtists.js
import React from "react";

const Blog = () => {
  return (
    <section className="mb-8 mt-3 text-[#363940] text-[14px]">
      <h2 className="text-[18px] font-semibold text-[#FC53A4] mb-4">
        Reaching out to artists: Pros, cons, and scripts
      </h2>
      <p className="pb-6">
        ** Disclaimer: The information provided here is intended for general
        guidance on reaching out to artists for fan projects - Team Lightcert is
        not responsible for any outcomes, responses, or decisions resulting from
        users' interactions with artists or their teams. Individual experiences
        may vary, and users are encouraged to follow professional and respectful
        communication practices when contacting artists or their teams.**
      </p>

      <p className="pb-6">
        Planning your own fan project can be challenging! One possible way to
        make the process easier is by reaching out to the artist prior to your
        show. Below is a comprehensive list of helpful information about
        contacting artists.
      </p>

      <h3 className="text-[18px] font-semibold mb-2 text-[#363940]">
        Pros and cons of reaching out to artists for the project:
      </h3>
      <p className="-mt-3 pb-6">
        Reaching out to artists for your project can offer several advantages:
      </p>
      <ul className="list-decimal pl-4 pb-3">
        <li>
          Support from artist’s staff: Our favorite artists have dedicated staff
          who can provide valuable assistance and support for your project. This
          could range from technical guidance to logistical help in getting the
          word out about your project.
        </li>
        <li>
          Support for projects on social media: Artists appreciate fans who go
          the extra mile to enhance the concert experience - reaching out to an
          artist or their team may potentially lead to acknowledgment or
          collaboration!
        </li>
      </ul>

      <p>Despite the benefits, there are potential drawbacks to consider:</p>
      <ul className="list-decimal pl-4 pt-3">
        <li>
          Possibility of Rejection: One concern is that artists might not
          respond, or decline the request to execute the project. In the worst
          case scenario, they might request that the project not take place at
          all.
        </li>
        <li>
          IP issues: IP, or intellectual property, refers to anything creative
          the artist or their team owns. This includes their image, songs, album
          covers, etc. In some cases, there is a chance that the artist may
          worry that you are misrepresenting the artist's brand and not want you
          to use their IP for a project, which would complicate things.
        </li>
        <li>
          Mistaken Identity: Sometimes, confusion can stem from audience members
          thinking that your fan project planning crew is part of the artist’s
          team. As a general rule, it is important to make sure that you clarify
          you are not directly connected with the team - whether or not you get
          direct approval.
        </li>
      </ul>

      <h3 className="text-[18px] font-semibold mb-2 mt-6 text-[#363940]">
        Script for requesting approval:
      </h3>
      <p className="pt-3">Subject: Possible Project at [Venue Name]</p>

      <p className="pt-6">Dear [Contact Person],</p>

      <p className="pt-6">
        I hope this message finds you well. My name is [Your Name], and I am a
        fan of [Artist Name]. I am writing to you to inform you about a fan
        project currently in the works, that aims to connect fans at [Venue
        Name] on [Show Date].
      </p>

      <p className="pt-6">
        I am part of [fan community] working on a project where we plan to
        create a light show during [Artist’s Name] performance using the app
        Lightcert. We were wondering if we could get your assistance in
        executing the project. This would primarily involve your approval for
        things like passing out papers, putting up fliers etc. [Inset more
        concert specifics here]
      </p>

      <p className="pt-6">
        Your support would mean a lot to us and the fan community! We look
        forward to the opportunity to discuss this further and address any
        questions you may have.
      </p>

      <p className="pt-6">
        Thank you in advance! <br /> [Your Full Name] <br /> [Your Contact
        Information] <br /> [Any Project Social Platforms]
      </p>

      <h3 className="text-[18px] text-[#363940] font-semibold  pt-6">
        Ways to get in touch with an artist:
      </h3>
      <p>
        There are usually many avenues to get in touch with an artist’s team.
        Here is a quick list of the most common and effective ways to do so:
      </p>
      <ul className=" list-item pl-1 ">
        <li>
          - <span className="font-semibold">Email</span>: Most artists have
          dedicated email addresses for inquiries or proposals. Check the
          artist’s official website for contact details.
        </li>
        <li>
          - <span className="font-semibold">Social Media</span> : Many artists
          actively use social media platforms. Direct messages or public
          mentions on platforms like Twitter, Instagram or Linkedin can
          sometimes grab their attention.
        </li>
        <li>
          - <span className="font-semibold">Official Website Forms</span>: Some
          artists have online forms specifically designed for inquiries. Fill
          out the relevant details and submit your proposal through their
          website.
        </li>
      </ul>

      <p className="pt-6">
        Remember to tailor your approach based on the specific communication
        channels preferred by the artist, and always maintain a professional and
        respectful tone in your interactions. Remember to have fun planning, and
        reach out to{" "}
        <a href="mailto:hello@lightcertapp.com" className="text-blue-600">
          hello@lightcertapp.com
        </a>{" "}
        with any additional questions!
      </p>
    </section>
  );
};

export default Blog;
