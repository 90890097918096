import React, { useState, useEffect } from "react";
import Container from "../../components/container";
import { useNavigate } from "react-router-dom";
import ViewColor from "./view-color";
import FinalView from "./final-view";

const ReviewSection = ({
  children,
  isNextButton,
  view,
  setView,
  setItemIndex,
  itemIndex,
  codeValue,
  access_code,
  setIsError,
  setErrText,
}) => {
  const navigate = useNavigate();
  const item_btns = ["Artist", "Fan Project"];

  const handleSaveData = async () => {
    navigate("/payment");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleClick();
    }
  };

  const handleClick = () => {
    if (view === 4) {
      if (!codeValue) {
        setErrText("Access code is required");
        setIsError(true);
      } else if (codeValue !== access_code) {
        setErrText("Invalid access code");
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
    view === 4
      ? codeValue === access_code && setView(view + 1)
      : view === 2
      ? setView(2)
      : setView(view + 1);
  };

  return (
    <Container>
      <div className=" justify-center 2xl:h-[600px] h-[430px] pt-3">
        <div className="w-full flex justify-center ">
          {view === 6 ? (
            <>
              <ViewColor setView={setView} view={view} />
            </>
          ) : view === 7 ? (
            <>
              <FinalView setView={setView} view={view} />
            </>
          ) : (
            <>
              <div className="relative z-20 ">
                <img
                  src="https://ik.imagekit.io/u7qex2chd/iphone5.svg?updatedAt=1705495576746"
                  className="bg-contain w-full h-[580px]"
                  alt=""
                />

                <>
                  <div className="absolute w-full  z-30 h-[570px] p-1 overflow-hidden  top-2 rounded-[50px]">
                    <div
                      style={{
                        background: `url(${"/assets/images/viewimg2.png"})`,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                      className=" bg-center h-[570px] w-full flex justify-center  pb-16 "
                    >
                      <div className="w-full">
                        <div className="text-white  pt-10 h-[100px] w-full">
                          {view === 3 && (
                            <div className=" flex justify-center gap-10 items-center">
                              {item_btns?.map((item, index) => (
                                <p
                                  onClick={() => setItemIndex(index)}
                                  className={`text-[12px]  underline-offset-4 cursor-pointer ${
                                    index === itemIndex &&
                                    "text-[#FC53A4] underline"
                                  }`}
                                >
                                  {item}
                                </p>
                              ))}
                            </div>
                          )}

                          {view === 4 && (
                            <div className="  items-center flex">
                              <div className="flex justify-start pl-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6 text-[#FC53A4] cursor-pointer"
                                  onClick={() => setView(3)}
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 19.5 8.25 12l7.5-7.5"
                                  />
                                </svg>
                              </div>
                              <div className="pl-[70px]">
                                <p className="text-[14px]  text-center cursor-pointer">
                                  Access Your <br /> Project
                                </p>
                              </div>
                            </div>
                          )}

                          {view === 5 && (
                            <div className="  items-center flex">
                              <div className="flex justify-start pl-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="w-6 h-6 text-[#FC53A4] cursor-pointer"
                                  onClick={() => setView(4)}
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M15.75 19.5 8.25 12l7.5-7.5"
                                  />
                                </svg>
                              </div>
                              <div className="pl-[93px]">
                                <img
                                  src="/assets/images/small-logo2.svg"
                                  alt=""
                                  className="cursor-pointer w-[28px]"
                                  onClick={() => setView(1)}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="items-center justify-center grid pt-24 px-6 w-full">
                          <div className="w-full">{children}</div>

                          <div
                            className={`w-full pt-28 ${view === 1 && "pt-56"}`}
                          >
                            {view < 5 && (
                              <button
                                onClick={handleClick}
                                onKeyDown={handleKeyDown}
                                style={{
                                  background:
                                    "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
                                }}
                                className={`text-white font-semibold absolute bottom-40   ${
                                  view === 4
                                    ? "w-[195px]"
                                    : view === 3
                                    ? "w-[120px]"
                                    : "w-[235px]"
                                } cursor-pointer z-30  rounded-[50px]  -mb-24 py-2 text-md `}
                                tabIndex="0"
                              >
                                {view === 4
                                  ? "Let’s Go"
                                  : isNextButton
                                  ? "Next"
                                  : "Get Started"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                {/* )} */}
              </div>
            </>
          )}
        </div>

        <div className="flex justify-center pt-8 pb-3">
          <button
            onClick={handleSaveData}
            style={{
              background: "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
            }}
            className="text-white font-semibold rounded-[50px] px-20 2xl:py-4 py-3 text-md"
          >
            Looks Good!
          </button>
        </div>
      </div>
    </Container>
  );
};

export default ReviewSection;
