import React from "react";
import { HOME_PLAN } from "../../constants/data";
import Container from "../../components/container";
import { useNavigate } from "react-router-dom";
const PlanSection = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full 2xl:pt-[150px] lg:pt-[150px] pt-8 flex justify-center 2xl:pb-32">
      <Container>
        <div>
          <p className="xl:pl-[10%] font-semibold text-[#000000] 2xl:text-[45px] lg:text-[40px] text-[28px]">
            Let’s plan your show
          </p>
          {HOME_PLAN.map((item) => (
            <p className="2xl:pt-14  pt-8 xl:pl-[10%] flex gap-2 2xl:text-[25px] lg:text-[20px] text-[21px] font-normal text-[#000000]">
              <img src="/assets/images/tick.svg" alt="" />
              {item}
            </p>
          ))}

          <div className="flex justify-center 2xl:pt-24 pt-10 2xl:px-[15%] lg:px-[17%] md:px-[20%]">
            <button
              onClick={() => navigate("/how-it-works")}
              style={{
                background:
                  "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
              }}
              className="text-white font-semibold rounded-[50px] px-10 2xl:py-5  py-3 w-full text-lg"
            >
              Next
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PlanSection;
