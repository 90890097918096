import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useState, useRef, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { storage } from "../../utils/firebase";

const StepThree = ({ step, setStep }) => {
  const fileInputRef = useRef(null);
  const songFileInputRef = useRef(null);
  const backgroundFileInputRef = useRef(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [songImage, setSongImage] = useState(null);
  const [songDetail, setSongDetail] = useState();
  const [bannerImagesName, setBannerImagesName] = useState(
    localStorage.getItem("banner-image-name") || "Upload image from device"
  );
  const [showImage, setShowImage] = useState(false);
  const [songImagesName, setSongImagesName] = useState(
    localStorage.getItem("song-image-name") || "Upload image from device  "
  );
  const [imgg, setimgg] = useState("/assets/images/latest-iphone.svg");
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const backgroundStyle = {
    backgroundImage: isHovered
      ? `linear-gradient(180deg, rgba(0,0,0,0.45279335855435929) 66%, rgba(0,0,0,0.45279335855435929) 67%, rgba(0,0,0,0.8429894369857318) 100%), url(${bannerImage})`
      : `linear-gradient(180deg, rgba(0,0,0,0.05279335855435929) 66%, rgba(0,0,0,0.05279335855435929) 67%, rgba(0,0,0,0.5429894369857318) 100%), url(${bannerImage})`,
    transition: "background-color 0.3s ease", // Optional: Add a transition for a smoother effect
    backgroundColor: "initial",
  };

  const backgroundStyle2 = {
    backgroundImage: isHovered2
      ? `linear-gradient(180deg, rgba(0,0,0,0.45279335855435929) 66%, rgba(0,0,0,0.45279335855435929) 67%, rgba(0,0,0,0.8429894369857318) 100%), url(${songImage})`
      : `linear-gradient(180deg, rgba(0,0,0,0.05279335855435929) 66%, rgba(0,0,0,0.05279335855435929) 67%, rgba(0,0,0,0.5429894369857318) 100%), url(${songImage})`,
    transition: "background-color 0.3s ease", // Optional: Add a transition for a smoother effect
    backgroundColor: "initial",
  };

  useEffect(() => {
    setimgg(imgg);
  }, [imgg]);

  // const handleFileChange = async (
  //   event,
  //   setImageFunction,
  //   fileInputRef,
  //   setImageNameFunction
  // ) => {
  //   if (fileInputRef.current) {
  //     fileInputRef.current.click();
  //   }

  //   const file = event.target.files && event.target.files[0];

  //   if (file) {
  //     try {
  //       const fileSizeInMegabytes = file.size / (1024 * 1024);
  //       if (fileSizeInMegabytes > 5) {
  //         console.error("File size exceeds 5MB limit.");
  //         return;
  //       }

  //       setImageNameFunction(file.name);

  //       const compressedDataURL = await compressImage(file);
  //       setImageFunction(compressedDataURL);
  //     } catch (error) {
  //       console.error("Error processing image:", error);
  //     }
  //   }
  // };

  const handleFileChange = async (
    event,
    setImageFunction,
    fileInputRef,
    setImageNameFunction
  ) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }

    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        const fileSizeInMegabytes = file.size / (1024 * 1024);
        if (fileSizeInMegabytes > 5) {
          console.error("File size exceeds 5MB limit.");
          return;
        }

        const storageRef = ref(storage, `/files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              localStorage.setItem("banner-image", url);
              localStorage.setItem("banner-image-name", file.name);
              setBannerImage(url);
            });
          }
        );
        setImageNameFunction(file.name);
        const compressedDataURL = await compressImage(file);
        setImageFunction(compressedDataURL);
      } catch (error) {
        console.error("Error processing image:", error);
      }
    }
  };

  const handleSongImageChange = async (
    event,
    setImageFunction,
    fileInputRef,
    setImageNameFunction
  ) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }

    const file = event.target.files && event.target.files[0];

    if (file) {
      try {
        const fileSizeInMegabytes = file.size / (1024 * 1024);
        if (fileSizeInMegabytes > 5) {
          console.error("File size exceeds 5MB limit.");
          return;
        }

        const storageRef = ref(storage, `/songImages/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              localStorage.setItem("song-image", url);
              localStorage.setItem("song-image-name", file.name);

              setSongImage(url);
            });
          }
        );
        setImageNameFunction(file.name);
        const compressedDataURL = await compressImage(file);
        setImageFunction(compressedDataURL);
      } catch (error) {
        console.error("Error processing song image:", error);
      }
    }
  };

  const compressImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800;
          const MAX_HEIGHT = 600;
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          const compressedDataURL = canvas.toDataURL("image/jpeg", 0.8);
          resolve(compressedDataURL);
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    const storedValuesString = localStorage.getItem("details");
    const storedValues = JSON.parse(storedValuesString);
    setSongDetail(storedValues);
  }, [step]);

  // useEffect(() => {
  //   if (songImage) {
  //     localStorage.setItem("song-image", songImage);
  //     localStorage.setItem("song-image-name", songImagesName);
  //   }
  // }, [songImage, songImagesName]);

  useEffect(() => {
    const processImage = async () => {
      const banner = localStorage.getItem("banner-image");
      setBannerImage(banner);

      const songImage = localStorage.getItem("song-image");
      setSongImage(songImage);

      await new Promise((resolve) => setTimeout(resolve, 300));
      setShowImage(true);
    };

    processImage();

    return () => {};
  }, []);

  if (imgg == null) {
    return null;
  }

  const handleSubmit = () => {
    if (bannerImage && songImage) {
      setStep(4);
    } else {
      toast.error("Images are required");
    }
  };

  const handleBannerDelete = () => {
    localStorage.removeItem("banner-image-name");
    localStorage.removeItem("banner-image");
    setBannerImage(null);
    setBannerImagesName("Upload image from device");
    setIsHovered(false);
  };

  const handleSongDelete = () => {
    localStorage.removeItem("song-image-name");
    localStorage.removeItem("song-image");
    setSongImage(null);
    setSongImagesName("Upload image from device");
    setIsHovered2(false);
  };

  return (
    <div className="2xl:flex lg:flex 2xl:py-10 py-6">
      <div className="2xl:w-[50%] w-full  flex  2xl:justify-start lg:justify-start justify-center  2xl:pl-40 lg:pl-40 ">
        <div className=" relative z-10 ">
          <img src={imgg} className="bg-contain" alt="" />
          <ToastContainer />
          {showImage && (
            <>
              {bannerImage ? (
                <div className="w-full h-[210px] z-20 top-[100px]  absolute px-[27px]">
                  <div
                    key={bannerImage}
                    style={backgroundStyle}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    className="w-full h-full bg-center cursor-pointer bg-cover shadow-inner rounded-md p-2 flex items-end relative"
                  >
                    {isHovered && (
                      <div
                        onClick={handleBannerDelete}
                        className="absolute top-16 left-[90px] w-14 h-14  flex justify-center items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-8 h-8 text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </div>
                    )}
                    <div className="-space-y-[2px] ">
                      <p className="text-[19px] text-[#fff] capitalize font-semibold ">
                        {songDetail?.name}
                      </p>
                      <p className="text-[12px] text-[#FC53A4] capitalize font-semibold">
                        {songDetail?.venue}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full h-[210px] z-20 top-[100px]  absolute px-[30.5px]">
                  <div className="w-full h-full bg-center relative bg-no-repeat bg-[#cccccc] shadow-inner   rounded-md p-2 flex items-end">
                    <img
                      src="/assets/images/plus.svg"
                      className="absolute top-16 left-20"
                      alt=""
                    />
                    <div className="-space-y-[2px] ">
                      <p className="text-[19px] capitalize text-[#fff] font-semibold ">
                        {songDetail?.name}
                      </p>
                      <p className="text-[12px] capitalize text-[#FC53A4] font-semibold">
                        {songDetail?.venue}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="w-full absolute h-[58px]  2xl:bottom-[210px] lg:bottom-[160px] md:bottom-[160px] bottom-[160px]  flex items-center gap-1 pl-9">
                {songImage ? (
                  <div
                    onClick={handleSongDelete}
                    style={backgroundStyle2}
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                    className="w-[34px] h-[34px] bg-center bg-cover cursor-pointer rounded-md bg-[#cccccc] flex justify-center items-center p-2 relative"
                  >
                    {isHovered2 && (
                      <div className="absolute top-0 w-[34px] h-[34px]  flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="w-6 h-6 text-white"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="w-[34px] h-[32px] rounded-md bg-[#cccccc] flex justify-center items-center p-2">
                      <img src="/assets/images/plus.svg" alt="" />
                    </div>
                  </>
                )}
                <p className="text-[13px] text-white capitalize bg-[#01010B] pl-[6px]">
                  {songDetail?.title}
                </p>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="2xl:w-[50%] w-full 2xl:pt-20 lg:pt-20 md:pt-20 pt-8 flex justify-center ">
        <div>
          <p className="2xl:text-[40px] lg:text-[28px] text-[25px]  text-[#070E15]  font-normal">
            Select your images
          </p>

          <div className=" justify-center 2xl:pt-7 pt-6 2xl:pb-7 pb-4">
            <p className="2xl:text-[20px] text-[16px] pb-1 text-left text-[#425466] font-semibold ">
              Upload background image
            </p>

            <input
              key={bannerImage}
              type="file"
              className="hidden"
              onChange={(event) =>
                handleFileChange(
                  event,
                  setBannerImage,
                  backgroundFileInputRef,
                  setBannerImagesName
                )
              }
              ref={backgroundFileInputRef}
              id="backgroundImageInput"
            />

            <div
              onClick={() => backgroundFileInputRef?.current?.click()}
              className="2xl:w-[498px] lg:w-[498px] md:w-[450px] w-full  2xl:h-[60px] cursor-pointer h-[46px] px-4 bg-[#EDF2F7] rounded-[6px] flex items-center justify-between"
            >
              <div className="w-[90%] 2xl:h-[30px] lg:h-[30px] h-[23px] overflow-hidden">
                <p className="2xl:text-[20px] text-[15px]  pr-2  text-[#7A828A] font-semibold ">
                  {bannerImagesName}
                </p>
              </div>

              <img src="/assets/images/upload22.svg" alt="" />
            </div>
          </div>

          <div className=" justify-center  2xl:pb-7 pb-4">
            <p className="2xl:text-[20px] text-[16px] pb-1 text-left text-[#425466] font-semibold ">
              Upload song image
            </p>
            <input
              key={songImage}
              type="file"
              className="hidden"
              onChange={(event) =>
                handleSongImageChange(
                  event,
                  setSongImage,
                  songFileInputRef,
                  setSongImagesName
                )
              }
              ref={songFileInputRef}
              id="songImageInput"
            />

            <div
              onClick={() => songFileInputRef.current.click()}
              className=" 2xl:w-[498px]   lg:w-[498px] md:w-[450px] w-full  2xl:h-[60px] cursor-pointer h-[46px] px-4 bg-[#EDF2F7] rounded-[6px] flex items-center justify-between"
            >
              <div className="w-[90%] 2xl:h-[30px] lg:h-[30px] h-[23px] overflow-hidden">
                <p className="2xl:text-[20px] text-[15px]  pr-2  text-[#7A828A] font-semibold ">
                  {songImagesName}
                </p>
              </div>
              <img src="/assets/images/upload22.svg" alt="" />
            </div>
          </div>
          <div className="flex justify-center 2xl:pt-12 lg:pt-12 pt-5 2xl:px-[15%] lg:px-[20%] md:px-[20%] ">
            <button
              onClick={handleSubmit}
              style={{
                background:
                  "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
              }}
              className="text-white font-semibold rounded-[50px] px-10 2xl:py-5  py-3 w-full text-md"
            >
              Next
            </button>
          </div>
          <p className="2xl:text-[14px]  text-[14px] 2xl:pt-6 pt-2 2xl:pb-20 pb-5 text-center text-[#858688] font-normal ">
            Questions? Check out our{" "}
            <span
              onClick={() =>
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/lightcert-subscription.appspot.com/o/Lightcert%20Customization%20Guide%20(1).pdf?alt=media&token=4c7b94a3-938a-435c-9836-9e0b3f96268e"
                )
              }
              className="text-[#FC53A4] underline cursor-pointer"
            >
              Customization Guide
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
