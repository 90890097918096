import React from "react";
import Container from "../../components/container";
import { useNavigate } from "react-router-dom";
import Paypal from "./paypal";
const PaymentSection = () => {
  const navigate = useNavigate();
  const access_code = (() => {
    return window.localStorage.getItem("access_code") || "XXXX";
  })();

  return (
    <Container>
      <div className="w-full 2xl:pt-10 pt-6">
        <img
          onClick={() => navigate("/review")}
          src="/assets/images/back.svg"
          className="cursor-pointer 2xl:w-20 w-14"
          alt=""
        />
      </div>

      <div className="2xl:pt-10 lg:pt-10 md:pt-10 pt-2  xl:px-20">
        <div className="pt-6 ">
          <p className="2xl:text-[48px] lg:text-[28px]  text-[24px] font-bold text-[#000] text-left">
            Confirm and Pay
          </p>
        </div>

        <div className="w-full 2xl:flex lg:flex pt-4">
          <div className="2xl:w-[60%] lg:w-[60%] w-full 2xl:pr-6 lg:pr-6">
            <div className="flex justify-between items-center pb-5 border-b border-[#E6E8EC]">
              <p className="2xl:text-[24px] text-[18px]  text-left text-[#23262F] font-semibold ">
                Your Payment Method
              </p>

              <div>
                <img src="/assets/images/paypal.svg" alt="" />
              </div>
            </div>

            <p className="2xl:text-[20px] pt-6 text-[16px]  text-left text-[#000000] font-semibold ">
              Your Project Code: {access_code}
            </p>
            <p className="2xl:text-[20px] text-[16px] -mt-1 text-left text-[#000000] font-bold ">
              Please save and write this code on paper or on a device.
            </p>
            <p className="2xl:text-[20px] pt-6 text-[16px]  text-left text-[#363940] font-normal ">
              By purchasing this service, you agree to{" "}
              <a
                href="https://www.lightcertapp.com/user-agreement#ppyes"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-[#EE4696]">
                  Lightcert’s User Agreement
                </span>{" "}
              </a>
              <br /> and {""}
              <a
                href="https://www.lightcertapp.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                <span className="text-[#EE4696] cursor-pointer">
                  Privacy Policy
                </span>
                .
              </a>
            </p>

            <Paypal />
          </div>
          <div className="2xl:w-[40%] lg:w-[40%] w-full 2xl:pl-6 lg:pl-6 2xl:pt-0 lg:pt-0 pt-5 2xl:pb-0 lg:pb-0 pb-5">
            <p className="2xl:text-[24px] text-[18px]  text-left text-[#23262F] font-semibold ">
              Summary
            </p>
            <div className="flex justify-between items-center">
              <p className="2xl:text-[16px] pt-6 text-[14px]  text-left text-[#777E90] font-normal ">
                One Fan Project Design
              </p>
              <p className="2xl:text-[16px] pt-6 text-[14px] pr-3 text-left text-[#23262F] font-semibold">
                $50
              </p>
            </div>
            <div className="w-full mt-6 rounded-lg bg-[#FC53A440] h-[51px] flex justify-between items-center px-3">
              <p className="2xl:text-[16px]  text-[14px]  text-left text-[#23262F] font-normal ">
                Total <span className="text-[#777E90]">(USD)</span>
              </p>
              <p className="2xl:text-[16px]  text-[14px]  text-left text-[#23262F] font-semibold">
                $50
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default PaymentSection;
