import { addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import { database } from "./firebase";

export const storeStep1 = async (code) => {
  console.log("code", code);
  try {
    const ref = collection(database, "project_details");
    // const docRef = doc(ref, code.id);
    const eventDate = new Date(code.details.date);
    const deleteAt = new Date(eventDate);

    deleteAt.setDate(eventDate.getDate() + 1);
    deleteAt.setHours(new Date().getHours());
    deleteAt.setMinutes(new Date().getMinutes());

    const newCode = {
      ...code,
      deleteAt: deleteAt.toISOString(),
    };
    const docRef = await addDoc(ref, newCode);
  } catch (error) {
    console.error("Error storing document:", error);
  }
};

const getAllProjectDetails = async () => {
  const ref = collection(database, "project_details");
  try {
    const querySnapshot = await getDocs(ref);
    const projectDetails = [];

    querySnapshot.forEach((doc) => {
      projectDetails.push(doc.data());
    });

    return projectDetails;
  } catch (error) {
    throw error;
  }
};

export const fetchProjectDetails = async () => {
  return await getAllProjectDetails();
};

export const projectDetails = await fetchProjectDetails();
