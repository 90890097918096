import React from "react";
import Container from "../../components/container";
import { useNavigate } from "react-router-dom";
const HowSection = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full 2xl:pt-10 pt-4 pb-4">
      <Container>
        <img
          src="/assets/images/logoo.svg"
          onClick={() => navigate("/")}
          className="cursor-pointer"
          alt=""
        />

        <div className="flex justify-center 2xl:pt-6 lg:pt-6 pt-8">
          <p className="2xl:text-[85px] lg:text-[75px] text-[55px] font-semibold text-[#000]">
            How it works
          </p>
        </div>
        <div className="flex justify-center 2xl:pt-[50px] pt-6">
          <img
            src="/assets/images/how-it-work.png"
            alt=""
            className="2xl:w-[70%] lg:w-[60%] w-full"
          />
        </div>
        <div className="text-center">
          <p className="2xl:text-[24px]  lg:text-[18px] md:text-[18px] text-[21px] font-normal 2xl:pt-8 lg:pt-6 pt-8  text-[#858688]">
            Welcome to Lightcert!
          </p>
          <p className="2xl:text-[24px] lg:text-[18px] md:text-[18px] text-[21px]  font-normal 2xl:pt-8 pt-2 text-[#858688]">
            Here, you’ll be able to create a Lightcert fan project of your own.
          </p>
          <p className="2xl:text-[24px] lg:text-[18px] md:text-[18px] text-[21px]  font-normal 2xl:pt-8 pt-1 text-[#858688] 2xl:px-[25%] xl:px-[25%] lg:px-[20%] md:px-[12%] ">
            Fans can download and participate in fan projects using the Fan side{" "}
            of Lightcert, and for your project, people will join with an access
            code that you’ll choose.
          </p>
        </div>

        <div className="flex justify-center 2xl:mb-8 2xl:pt-20 lg:pt-6 pt-[77px] xl:px-[35%] lg:px-[30%] md:px-[20%]">
          <button
            onClick={() => navigate("/warning")}
            style={{
              background: "linear-gradient(92deg, #CA0B66 0%, #FC53A4 106.67%)",
            }}
            className="text-white font-semibold rounded-[50px] px-10 2xl:py-5  py-3 w-full text-lg"
          >
            Continue
          </button>
        </div>
      </Container>
    </div>
  );
};

export default HowSection;
