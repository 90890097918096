export const HOME_PLAN = [
  "100% customizable.",
  "See your design in real time. ",
  "Pay after you design.",
];

export const ARTIST = [
  "Select your artist, venue, and song",
  "Hold up your phone once the color appears",
  "Take a look around the venue",
];

export const FAN = [
  "Enter the Access Code for your fan project ",
  "Hold up your phone once the color appears",
  "Take a look around the venue",
];

export const SOCIAL_ICONS = [
  {
    icon: "/assets/images/insta.svg",
    url: "https://www.instagram.com",
  },
  {
    icon: "/assets/images/twitter.svg",
    url: "https://twitter.com",
  },
  {
    icon: "/assets/images/snapchat.svg",
    url: "https://web.snapchat.com",
  },
  {
    icon: "/assets/images/tiktok.svg",
    url: "https://www.tiktok.com/en",
  },
];

export const fanProjectGuide = [
  {
    title: "A Multi-Step Guide to Contacting Volunteers on Social Media",
    content: [
      "The best way to create a successful fan project is with, well, other fans! In this guide, we’ll share a multi-step strategy to rally volunteers through social media to ensure the success of your project.",
      "**1. DMing fan accounts:** One great way to get the word out is to reach out to influential fan accounts. Use a friendly DM template to reach out to as many fan accounts as possible before your show. You can use this template as a starting point!",
      "\"Hi! We're planning a fan project for [Artist] at [Venue] on [Date]. Seeking volunteers, and we'd love you to share info on your account to spread the word. Find more details on our page!\"",
      "After, attach a post with project specifics so that your project can be easily shared.",
      "**2. Project promotion on your account:**  Creating visually appealing posts with all project details written clearly is a great way to spread awareness about your project. Utilize clear graphics, like this example post here, and consider adding a QR code for easy access to information. Use this QR code generator to streamline the process! You can use websites like Canva for easy editing and several design templates. Your posts should not just inform but excite followers into joining the project.",
      "**3. DMing potential volunteers:** Sliding into the DMs of followers of your artist or fans who commented on fan accounts can also be an effective strategy in getting the word out about your project! ",
      "You can use a template like: 'Hey!! We're planning a fan project for [Artist] at [Venue] on [Date]. Let us know if you're going to the show and would be interested in volunteering!' Personalize your message to match your project details, and remember to engage with those interested directly.",
      "**Nurturing responses:** As responses flood in, make sure your information is readily available. Provide links to apps or easily accessible resources for those wanting to contribute, turning enthusiasm to active participation.",
      "**Covering all fronts:** Extend your strategy across all major platforms where your fan community thrives. Instagram and Twitter, being popular hubs for fan activity, are excellent starting points. Remember to tailor your approach to suit your fanbase, and use feedback from your conversations to change your strategy for the next person you DM.",
    ],
  },
  {
    title: "Using Papers to Plan Your Project",
    content: [
      "When planning your fan project, the use of colored papers in addition to the use of Lightcert can be another way to spread the word about your project. In the past, fans would use papers like the ones you will learn about in this post, holding them over their mobile phone lights, to create light shows. In this blog post, we discuss the logistics of planning with papers – estimating quantities, designing templates, printing, and distributing.",
      "**1. Designing your papers:** Begin by designing papers! Use the provided Canva template to create an editable version tailored to your project. When you’ve finished, you will be printing this design on colored paper.",
      "**2. Printing strategy:** When it comes to printing, estimation is key. If you're flying solo, print 1.5x or 2x the number of seats in the venue. For instance, if you’re at a 1000-seat venue, aim to print and cut 1,500 or 2,000 pieces of paper. In the template provided, this would be around 67 copies. For printing, utilize resources like FedEx, your own printer, or school printing services to print in large quantities.",
      "**3. Cutting efficiency:** Maximize efficiency when cutting the papers. Utilize large paper cutters to expedite the process, allowing you to cut multiple pages simultaneously. This not only saves time but ensures uniformity in the size and presentation of your paper elements. If possible, get support from other fans for this task!",
      "**4. Distribution tactics:** Bringing your papers into the venue requires some planning. Consider distributing them ahead of time or placing them in clear plastic bags. This not only eases the passage through venue security but also ensures that attendees identify and become excited for your project.",
      "**5. Get creative and collaborative:** Collaborate with fellow fans or recruit volunteers to assist with all of the steps described above! Whether it's enlisting friends or tapping into the fan community's collective energy, working together enhances the reach and effectiveness of your project.",
    ],
  },
  {
    title: "IRL Ground Marketing for Your Project",
    content: [
      "As your concert approaches, the impact of real-world ground marketing will be paramount. In this blog post, we delve into the intricacies of organizing right before a show, turning the buzz generated online into results.",
      "**1. Strategic planning over social media:** The key to seamless ground marketing is laying the groundwork over social media. Create group chats to coordinate efforts, confirm volunteers' commitment, discuss sections to cover, and ensure everyone is equipped with the necessary materials. The day before the show, reconfirm details – who's bringing papers, what sections volunteers will cover, and where and if/when and where the pre-show meetup will take place.",
      "**2. Organizers' and volunteers’ pre-show meetup:** Organizers play a crucial role in setting the stage. Meet well before the show to synchronize efforts and fine-tune the execution plan. Gather with volunteers before the show as well to distribute printed papers, if that's part of your plan. Empower volunteers with extra papers and encourage them to share with those around them.",
      "A great moment for project exposure is when fans are queuing to enter the venue. Go down the line engaging with them, sharing the excitement about your project, and distribute papers to amplify awareness.",
      "**3. Spreading the word:** As you talk to fans, share the essence of the fan project and convey your excitement. Keep it clear, concise, and compelling, ensuring that the details resonate. The goal is not just distribution, but to hype up the crowd and get them excited to participate.",
      "**5. Social media check-Ins:** Directly before the show starts, remember to remain in contact with volunteers through social media check-ins. Ensure everyone is in sync, address any last-minute concerns, and build a sense of unity among the team.",
    ],
  },
  {
    title: "Choosing Meaningful Colors for Your Project",
    content: [
      "The most effective fan projects choose meaningful colors to create a unifying and visually captivating experience for both the artist and audience. In this guide, we delve into the art of choosing colors that resonate with the music, set the right vibe, and ensure visibility from a distance.",
      "**1. Meaningful colors:** Color choices should be meaningful. Perhaps the most intuitive approach is drawing inspiration from the lyrics of the song itself. Think about the song 'Yellow' by Coldplay – choosing yellow as a color not only pays homage to the track but also establishes a direct connection with the artist’s narrative. For example, for one of Lightcert's Harry Styles projects in 2021, we chose the colors blue and pink because it was for the album Fine Line. Pink and blue were the color scheme for the album, which made them meaningful for that project. You can see the results of that project here!",
      "Beyond lyrical cues, consider how colors align with the genre of music. Fun pop melodies might be best accompanied with bright and playful colors like hot pinks, while genres like rock music may find the best counterpart in strong, bold hues like reds or blues. Another thing to think about is the emotions the song conveys – angry songs may call for reddish tones, while sad songs may be complemented by calming blues or purples.",
      "**2. Visibility matters:** While meaning is important, visibility should also be taken into consideration. Picture a large venue with thousands of fans - your light show should be visible from any seat, at any distance. Avoid subtle pastels or colors too close to white, as they may fade into the background or get lost in the sea of lights. Opt for bold and vibrant colors that pop, ensuring your fan project stands out and commands attention.",
      "**3. Examples from the past:** Before you choose your project color, take some time to do some research into past fan projects. This not only prevents unintentional repetition and poor color choice, but also can serve as inspiration for your project. Some of our favorite examples are The Rainbow Project for Harry Styles in 2018 and the So Much For Stardust Fallout Boy Project.",
      "In the end, your color choice is up to you and your other organizers! Remember to have chosen something meaningful to you, and most importantly, have fun! Happy planning!",
    ],
  },
];
