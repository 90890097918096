import React from "react";
import Container from "../../components/container";
const BannerSection = () => {
  return (
    <div
      className="w-full 2xl:min-h-[100vh] lg:min-h-[100vh] min-h-auto pb-40 bg-black bg-center bg-no-repeat bg-cover pt-6 "
      style={{
        backgroundImage: "url(/assets/images/home-banner.png)",
      }}
    >
      <Container>
        <img src="/assets/images/logo.svg" alt="" />
        <div className="xl:pt-16 xl:pl-16 pt-10 ">
          <p className="xl:text-[38px] lg:text-[38px] md:text-[38px] text-[28px] font-semibold text-white">
            Welcome to Lightcert!
          </p>
          <p className="text-[18px] font-normal text-white">
            Let’s put on a show together.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default BannerSection;
