import React from "react";
import BannerSection from "./banner-section";
import PlanSection from "./plan-section";
const Main = () => {
  return (
    <div className="w-full  bg-center flex flex-col xl:flex-row 2xl:pb-0 lg:pb-0 md:pb-4 pb-6">
      <BannerSection />
      <PlanSection />
    </div>
  );
};

export default Main;
