import React, { useState } from "react";
import Container from "../../components/container";
import Accordian from "../../components/accordian";
import { useNavigate } from "react-router-dom";
import Blog from "./blog";
import BlogSection from "./blog-section";
import { fanProjectGuide } from "../../constants/data";
const SuccessSection = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);

  return (
    <div className="w-full 2xl:pt-10 pt-4">
      <Container>
        <img
          onClick={() => {
            navigate("/");
            localStorage.removeItem("step");
          }}
          src="/assets/images/logoo.svg"
          className="cursor-pointer"
          alt=""
        />

        <div className="2xl:pt-8 lg:pt-8  2xl:px-20 lg:px-20  ">
          <p className="2xl:text-[48px] lg:text-[28px]  text-[24px] font-bold text-[#000] text-left">
            Payment Successful!
          </p>
          <div className="h-[0.5px] bg-[#E6E8EC] w-full mt-5"></div>
          <p className="2xl:text-[20px] pt-6 text-[16px]  text-left text-[#363940] font-normal ">
            A receipt has been sent to your email.
          </p>

          <p className="2xl:text-[20px] pt-6 text-[16px]  text-left text-[#363940] font-normal ">
            Looking for some tips for planning a successful project? <br />
            Check out our blog below!
          </p>
        </div>

        <div className="2xl:flex lg:flex w-full 2xl:py-16 py-8">
          <div className="2xl:w-[40%] lg:w-[40%] w-full 2xl:px-20 lg:px-20 ">
            <div className="w-full border border-[#E1E5EE] p-7  h-auto  rounded-sm">
              <Accordian setOpenIndex={setOpenIndex} openIndex={openIndex} />
            </div>
          </div>

          <div className="2xl:w-[60%] lg:w-[60%] w-full 2xl:px-20 lg:px-20 2xl:pt-0 lg:pt-0 pt-5 ">
            <div className="w-full border p-3">
              <div className="border-[#E1E5EE] p-4 rounded-sm max-h-[500px] min-h-[500px] overflow-auto h-auto">
                {openIndex === 0 ? (
                  <Blog />
                ) : openIndex >= 1 && openIndex <= 4 ? (
                  <BlogSection
                    fanProjectGuide={fanProjectGuide[openIndex - 1]}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SuccessSection;
