import React from "react";
import { useNavigate } from "react-router-dom";
const Steps = ({ setCurrent, current, setStep }) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    if (current === 1) {
      navigate("/warning");
    } else {
      setCurrent(current - 1);
      setStep(current - 1);
    }
  };

  return (
    <div className="w-full 2xl:pt-10 pt-6">
      <img
        onClick={handleButtonClick}
        src="/assets/images/back.svg"
        className="cursor-pointer 2xl:w-20 w-14"
        alt=""
      />

      <div className=" 2xl:pt-6  2xl:px-40 lg:px-40">
        <p className="text-[#000000] text-[18px] font-semibold pt-5">
          Step <span className="font-bold">{current}</span> of{" "}
          <span className="font-bold">4</span>
        </p>
        <p className="2xl:text-[48px] lg:text-[28px]  text-[24px] font-bold text-[#000] text-left">
          Customize your project
        </p>
      </div>
    </div>
  );
};

export default Steps;
