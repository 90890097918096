import React, { useMemo } from "react";
import { collapse } from "@growthops/ext-ts";

const Container = ({ className = "", children }) => {
  const classes = useMemo(
    () => ({
      root: collapse(["container", className]),
    }),
    [className]
  );

  return <div className={classes.root}>{children}</div>;
};

export default Container;
export { Container };
