import React, { useState } from "react";

const Accordian = ({ setOpenIndex, openIndex }) => {
  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const accordionData = [
    {
      title: "Reaching out to an artist",
      index: 0,
    },
    {
      title: "Contacting volunteers on social media",
      index: 1,
    },
    {
      title: "Using papers to plan your project",
      index: 2,
    },
    {
      title: "IRL ground marketing for your project",
      index: 3,
    },
    {
      title: "Choosing meaningful colors for your project",
      index: 4,
    },
  ];
  return (
    <div className="w-full">
      {accordionData.map((item, index) => (
        <div key={index} className="mb-4">
          <div
            className="flex items-start cursor-pointer"
            onClick={() => handleToggle(index)}
          >
            <div className="mr-2 mt-[5px]">
              {openIndex === index ? (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 text-[#FC53A4]"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M5 12h14"
                    />
                  </svg>
                </>
              ) : (
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-4 h-4 "
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                </>
              )}
            </div>
            <div
              className={`font-normal text-[#000000] text-[16px] pl-2 ${
                openIndex === index && "text-[#FC53A4]"
              }`}
            >
              {item.title}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordian;
